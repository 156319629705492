import {Module} from '../interfaces/module.interface';
import {CREATED_ON} from './shared/created-on';
import {PipeType} from '../enums/pipe-type.enum';
import JSX from '../jsx.compiler';

export const PROJECTS_MODULE: Module = {
  id: 'projects',
  name: 'Projects',
  layout: {
    sort: CREATED_ON.sort,
    table: {
      hideAdd: true,
      hideImport: true,
      tableColumns: [
        CREATED_ON.column(),
        {
          key: '/name',
          label: 'Name'
        },
        {
          key: '/origin',
          label: 'Origin',
          pipe: [PipeType.Custom, PipeType.Sanitize],
          pipeArguments: [
            (origin) => {
              return `<a href="${origin}" target="_blank">${origin}</a>`
            }
          ]
        },
        {
          key: '/id',
          label: 'SDK',
          pipe: [PipeType.Custom, PipeType.Custom],
          pipeArguments: {
            0: async (_, row) => {
              const cache = window.jms.util.getCache(`sdk_${row.id}`);
              if (cache !== undefined) {
                return cache;
              }

              try {
                const content = await fetch(row.origin)
                  .catch(() => {
                    return fetch(
                      `https://us-central1-ace-analyzee.cloudfunctions.net/api-proxy/${row.origin}`
                    );
                  })
                  .then((response) => response.text());

                const hasScript = new RegExp(`cdn\\.analyzee\\.io\\/sdk\\/${row.id}\\.js`, 'gm').test(content);
                window.jms.util.setCache(`sdk_${row.id}`, hasScript, 5 * 60 * 1000);
                return hasScript;
              } catch (error) {
                // ignore
                window.jms.util.setCache(`sdk_${row.id}`, false, 5 * 60 * 1000);
                return false;
              }
            },
            1: (installed) => {
              if (installed) {
                return '<div class="flex items-center"><span class="dot green" title="SDK installed"></span>&nbsp;Installed</div>';
              } else {
                return '<div class="flex items-center"><span class="dot red" title="Missing SDK"></span>&nbsp;Missing</div>';
              }
            }
          },
          resolveObservables: true
        }
      ]
    },
    instance: {
      segments: [
        {
          fields: [
            '/name',
            '/origin',
            '/slug',
            '/createdOn'
          ]
        }
      ],
      actions: [
        {
          value: (row) => {
            const filter = [
              {
                value: row.id,
                displayValue: row.data.name,
                key: 'project',
                operator: '==',
                label: 'Project'
              }
            ];

            return JSX(<jms-e-link icon="group" link={'/m/project-permissions?filter=' + encodeURIComponent(JSON.stringify(filter))}>Members</jms-e-link>);
          }
        }
      ]
    }
  },
  schema: {
    properties: {
      id: {type: 'string'},
      name: {type: 'string'},
      origin: {type: 'string'},
      slug: {type: 'string'},
      ...CREATED_ON.property
    }
  },
  definitions: {
    createdOn: {
      label: 'Created on',
      disableOn: 'edit',
      component: {
        type: 'date',
        configuration: {
          includeTime: true
        }
      }
    }
  }
};
