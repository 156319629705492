/**
 * Shared public environment config
 */
export const ENV_CONFIG = {
  firebase: {
    apiKey: 'AIzaSyDRRPzNDA_BD4CfgtaunLptZj1ZBfv4Fvs',
    authDomain: 'ace-analyzee.firebaseapp.com',
    projectId: 'ace-analyzee',
    storageBucket: 'ace-analyzee.appspot.com',
    messagingSenderId: '321170041148',
    appId: '1:321170041148:web:9dae84c15985a25f751639',
    measurementId: 'G-RHT301VL1B',
    databaseURL: 'https://ace-analyzee-default-rtdb.firebaseio.com'
  }
};
