import {Module} from '../../interfaces/module.interface';
import {Collections} from '../../interfaces/collections';
import {PipeType} from '../../enums/pipe-type.enum';
import JSX from '../../jsx.compiler';

export const BLOG_ARTICLES_MODULE: Module = {
    id: Collections.BlogArticles,
    name: 'Blog Articles',
    layout: {
        editTitleKey: 'title',
        table: {
            hideImport: true,
            tableColumns: [
                {
                    key: '/id',
                    label: 'URL'
                },
                {
                    key: '/title',
                    label: 'Title'
                },
                {
                    key: '/active',
                    label: 'Active',
                    pipe: [PipeType.Custom],
                    pipeArguments: {
                        0: (active) => {
                            if (active) {
                                return '<span class="dot green" title="Active"></span>';
                            } else {
                                return '<span class="dot red" title="Inactive"></span>';
                            }
                        }
                    }
                },
                {
                    key: '/lastUpdatedOn',
                    label: 'Published',
                    pipe: [PipeType.Custom],
                    pipeArguments: {
                        0: (lastUpdatedOn) => {
                            if (lastUpdatedOn > window.jms.util.state.lastPublishedOn) {
                                return '<span class="dot red" title="Not published"></span>';
                            } else {
                                return '<span class="dot green" title="Published"></span>';
                            }
                        }
                    }
                }
            ]
        },
        instance: {
            segments: [
                {
                    fields: [
                        '/id',
                        '/active',
                        '/title',
                        '/author',
                        '/category',
                        '/publicationDate',
                        '/featuredImage',
                        '/shortDescription',
                        '/content'
                    ]
                },
                {
                    title: 'Meta',
                    fields: [
                        '/meta/title',
                        '/meta/description',
                        '/meta/keywords',
                        '/meta/structured',
                        '/meta/og/image'
                    ]
                }
            ]
        },
        overview: {
            toolbar: [`<jms-e-landing-publish></jms-e-landing-publish>`, 'add']
        }
    },
    schema: {
        properties: {
            id: {type: 'string'},
            title: {type: 'string'},
            active: {type: 'boolean'},
            author: {type: 'string'},
            category: {type: 'string'},
            publicationDate: {type: 'number'},
            lastUpdatedOn: {type: 'number'},
            featuredImage: {type: 'string'},
            shortDescription: {type: 'string'},
            content: {type: 'string'},
            meta: {
                type: 'object',
                properties: {
                    title: {type: 'string'},
                    description: {type: 'string'},
                    keywords: {type: 'string'},
                    structured: {type: 'string'},
                    og: {
                        type: 'object',
                        properties: {
                            image: {
                                type: 'string'
                            }
                        }
                    }
                }
            }
        }
    },
    definitions: {
        id: {
            label: 'URL',
            disableOn: 'edit'
        },
        title: {
            label: 'Title'
        },
        author: {
            label: 'Author',
            component: {
                type: 'select',
                configuration: {
                    populate: {
                        collection: Collections.BlogAuthors
                    }
                }
            }
        },
        category: {
            label: 'Category',
            component: {
                type: 'select',
                configuration: {
                    populate: {
                        collection: Collections.BlogCategories
                    }
                }
            }
        },
        publicationDate: {
            label: 'Publication Date',
            component: {
                type: 'date',
                configuration: {
                    format: 'number',
                    includeTime: true
                }
            },
            formatOnLoad: (value) => new Date(value || Date.now()).getTime()
        },
        lastUpdatedOn: {
            formatOnSave: () => Date.now()
        },
        featuredImage: {
            label: 'Featured Image',
            component: {
                type: 'image',
                configuration: {
                    filePrefix: 'blog-articles/'
                }
            }
        },
        content: {
            label: 'Content',
            component: {
                type: 'quill' as any
            }
        },
        shortDescription: {
            label: 'Short Description',
            component: {
                type: 'textarea'
            }
        },
        meta: {
            label: 'Meta'
        },
        'meta/title': {
            label: 'Meta Title'
        },
        'meta/description': {
            label: 'Meta Description',
            component: {
                type: 'textarea'
            }
        },
        'meta/keywords': {
            label: 'Meta Keywords'
        },
        'meta/structured': {
            label: 'Meta Structured Data',
            component: {
                type: 'textarea'
            }
        },
        'meta/og/image': {
            label: 'Open Graph Image',
            component: {
                type: 'image',
                configuration: {
                    filePrefix: 'blog-articles/'
                }
            }
        }
    }
};
