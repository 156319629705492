import {Module} from '../../interfaces/module.interface';
import {Collections} from '../../interfaces/collections';

export const BLOG_AUTHORS_MODULE: Module = {
  id: Collections.BlogAuthors,
  name: 'Blog Authors',
  layout: {
    editTitleKey: 'name',
    table: {
      hideImport: true,
      tableColumns: [
        {
          key: '/id',
          label: 'URL'
        },
        {
          key: '/name',
          label: 'Name'
        }
      ]
    },
    instance: {
      segments: [
        {
          fields: [
            '/id',
            '/image',
            '/name',
            '/about',
          ]
        },
        {
          title: 'Meta',
          fields: [
            '/meta/title',
            '/meta/description',
            '/meta/keywords',
            '/meta/structured',
            '/meta/og/image'
          ]
        }
      ]
    }
  },
  schema: {
    properties: {
      id: {type: 'string'},
      name: {type: 'string'},
      image: {
        type: 'string'
      },
      about: {type: 'string'},
      meta: {
        type: 'object',
        properties: {
          title: {type: 'string'},
          description: {type: 'string'},
          keywords: {type: 'string'},
          structured: {type: 'string'},
          og: {
            type: 'object',
            properties: {
              image: {type: 'string'},
            }
          }
        }
      }
    }
  },
  definitions: {
    id: {
      label: 'URL',
      disableOn: 'edit'
    },
    name: {
      label: 'Name'
    },
    about: {
      label: 'About',
      component: {
        type: 'quill' as any
      }
    },
    image: {
      label: 'Image',
      component: {
        type: 'image',
        configuration: {
          filePrefix: 'blog-authors/'
        }
      }
    },
    meta: {
      label: 'Meta'
    },
    'meta/title': {
      label: 'Meta Title'
    },
    'meta/description': {
      label: 'Meta Description',
      component: {
        type: 'textarea'
      }
    },
    'meta/keywords': {
      label: 'Meta Keywords'
    },
    'meta/structured': {
      label: 'Meta Structured Data',
      component: {
        type: 'textarea'
      }
    },
    'meta/og/image': {
      label: 'Open Graph Image',
      component: {
        type: 'image',
        configuration: {
          filePrefix: 'blog-authors/'
        }
      }
    }
  }
};
