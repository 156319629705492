import {PipeType} from '../enums/pipe-type.enum';

export const PROJECT_PERMISSIONS = {
  name: 'Project Permissions',
  description: 'Manage the permissions for each project',
  schema: {
    properties: {
      id: {type: 'string'},
      createdOn: {type: 'number'},
      project: {type: 'string'},
      role: {type: 'string'}
    }
  },
  layout: {
    instance: {
      segments: [
        {
          fields: [
            '/id',
            '/createdOn',
            '/project',
            '/role'
          ]
        }
      ]
    },
    table: {
      tableColumns: [
        {
          key: '/id',
          label: 'User',
          pipe: [PipeType.GetDocuments, PipeType.Custom, PipeType.Sanitize],
          pipeArguments: {
            0: (item, row) => {

              if (!row?.ref?.parent?.parent?.id) {
                return [];
              }

              return [`users/${row.ref.parent.parent.id}`];
            },
            1: ([item]) => {
              return `<span>${item.email || item.id}</span> <b style="float: right;">${[item.firstName, item.lastName].filter(Boolean).join(' ')}</b>`;
            }
          },
          resolveObservables: true
        },
        {
          key: '/createdOn',
          label: 'Created on',
          pipe: [PipeType.Date],
          sortable: true
        },
        {
          key: '/project',
          label: 'Project',
          populate: {
            collection: 'projects',
            key: 'name'
          }
        },
        {key: '/role', label: 'Role', pipe: [PipeType.Titlecase]}
      ]
    },
    filterModule: {
      clearFilters: {},
      persist: true,
      schema: {
        properties: {
          project: {type: 'string'},
          role: {type: 'string'},
        }
      },
      definitions: {
        project: {
          label: 'Project',
          component: {
            type: 'select',
            configuration: {
              populate: {
                collection: 'projects',
                key: 'name',
                orderBy: 'name'
              }
            }
          }
        },
        role: {
          label: 'Role',
          component: {
            type: 'select',
            configuration: {
              populate: {
                collection: 'roles',
                key: 'name'
              }
            }
          }
        }
      }
    }
  },
  definitions: {
    id: {
      label: 'ID',
    },
    createdOn: {
      label: 'Created On',
    },
    project: {
      label: 'Project',
      component: {
        type: 'select',
        configuration: {
          populate: {
            collection: 'projects',
            key: 'name'
          }
        }
      }
    },
    role: {
      label: 'Role',
      component: {
        type: 'select',
        configuration: {
          populate: {
            collection: 'roles',
            key: 'name'
          }
        }
      }
    }
  },
}
